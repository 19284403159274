import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";

import { getFirestore } from '@firebase/firestore'; 
import { getStorage, ref, uploadBytes, getDownloadURL} from '@firebase/storage';
import { getAuth, signInWithEmailAndPassword, signOut} from "firebase/auth";
//const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

const firebaseConfig = {
  apiKey: "AIzaSyD7uSTeIaO-SsZWa6Kb7rFtzKXbAE_YYT8",
  authDomain: "challengecouples-b6b8c.firebaseapp.com",
  projectId: "challengecouples-b6b8c",
  storageBucket: "challengecouples-b6b8c.appspot.com",
  messagingSenderId: "205835397911",
  appId: "1:205835397911:web:885d8107e6a8aaa4e62552",
  measurementId: "G-WWG0Z4VHDG"
};

const app = initializeApp(firebaseConfig);
/*const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lf9eKIhAAAAAOCJnNATShPLrEFFceMLVW0IG29r'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
const analytics = getAnalytics(app);
*/
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);

export async function uploadFile(file, name) {
  const storageRef = ref(storage, 'images/' + name)
  await uploadBytes(storageRef, file)
  const url = await getDownloadURL(storageRef)
  return url
}

export const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const logout = () => {
  signOut(auth);
};