
import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import '../css/LandingPage.css';
const Home = () => {


  const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [isBannerClosed, setIsBannerClosed] = useState(false);

  const closeBanner = () => {
    setIsBannerClosed(true);
  };

  const [showScrollButton] = useState(false);

  useEffect(() => {
    const sections = document.querySelectorAll(".section-fullscreen");


    const handleScroll = () => {
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;
        if (isInView) {
          section.classList.add("in-view");
        } else {
          section.classList.remove("in-view");
        }
      });

      // Mostrar u ocultar el botón de volver arriba
      const scrollTopButton = document.getElementById("scroll-top-button");
      if (window.scrollY > 100) {
        scrollTopButton.style.display = "block";
      } else {
        scrollTopButton.style.display = "none";
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="landing-page">
      <Navbar />

      <section id="hero" className="section-fullscreen">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="display-4">Bienvenido a Todeit</h1>
              <p className="lead">Tu aplicación para descubrir lugares increíbles.</p>
            </div>
            <div className="col-lg-6">
              <img src="/img/placeti_mobile.png" alt="Captura de pantalla de la aplicación" className="img-fluid" style={{ width: '380px' }} />
            </div>
          </div>
        </div>
      </section>
      <section id="features" className="section-fullscreen bg-custom">
        <div className="container text-center">
          <h2>Decide Dónde Ir en Grupo con Facilidad</h2>
          <h3>Una experiencia de elección de lugares colaborativa y sin complicaciones</h3>
          <p>Nuestra plataforma te permite crear una sala virtual y compartir un código único con tus amigos o pareja. Juntos, podrán explorar una variedad de lugares emocionantes, que incluyen restaurantes, parques y mucho más. ¡La decisión es completamente colaborativa! Cada miembro puede marcar sus lugares favoritos y cuando todos hagan 'match' en un lugar, la aplicación te lo notificará de inmediato. Es la forma perfecta de asegurarse de que todos estén contentos con la elección final. ¡Comienza a planificar tus salidas en grupo de manera eficiente y divertida!</p>
        </div>
      </section>
      <section id="download" className="section-fullscreen">
        <div className="container">
          <h2>Descarga la aplicación</h2>
          <div className="download-buttons mt-4">
            <a href="https://play.google.com/store/apps/details?id=com.decapps.placeti" target="_blank" rel="noopener noreferrer">
              <img src="/img/googleplay.webp" alt="Google Play Store" style={{ width: '200px' }} />
            </a>
            <a href="https://apps.apple.com/us/app/todeit-retos-en-parejas-y-m%C3%A1s/id6443490763" target="_blank" rel="noopener noreferrer">
              <img src="/img/ios.webp" alt="App Store" style={{ width: '180px', marginLeft: '15px' }} />
            </a>
          </div>
        </div>
      </section>
      <section id="contact" className="section-fullscreen">
        <div className="container text-center">
          <h2>Contacto</h2>
          <p>Si tienes alguna pregunta o comentario sobre nuestra app, envíanos un correo electrónico a:</p>
          <a href="mailto:todeitapp@gmail.com">todeitapp@gmail.com</a>
        </div>
      </section>

      <section id="footer" className="bg-color-primary text-white py-3">
        <div className="container text-center">
          <p>&copy; Derechos Reservados a Todeit | <a href="/privacy-policy" className="text-white">Política de Privacidad</a> | <a href="/terms-conditions" className="text-white">Términos y Condiciones</a></p>
        </div>
      </section>
      <button
        id="scroll-top-button"
        className={`scroll-top-button ${showScrollButton ? "show" : ""}`}
        onClick={scrollToTop}
      >
        Volver arriba
      </button>
      {!isBannerClosed && isMobileDevice && (
        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1000 }}>
          <div className="download-banner bg-color-primary border rounded p-2">
            <button type="button" className="btn-close" aria-label="Close" onClick={closeBanner}></button>
            <p>¡Descarga nuestra aplicación para dispositivos móviles!</p>
            <div className="d-flex">
              <a href="enlace-a-la-app-store" className="btn btn-secondary py-1 me-2">
                Descargar en la App Store
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.decapps.placeti" className="btn btn-success py-1">
                Descargar en Google Play
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
