import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./components/Home";
import DeleteAccount from "./auth/DeleteAccount";

import Login from "./auth/Login";
import Show from "./components/Show";
import Create from "./components/Create";
import Edit from "./components/Edit";

import PrivacyPolicy from "./legal/PrivacyPolicy";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/show" element={<Show />} />
          <Route exact path="/create" element={<Create />} />
          <Route exact path="/edit/:id" element={<Edit />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
