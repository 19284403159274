// src/components/Navbar.js
import React from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import '../css/LandingPage.css'; // Asegúrate de ajustar la ruta si es necesario

const Navbar = () => {
    const navigate = useNavigate();

    const handleNavigation = (sectionId) => {
      navigate(`/#${sectionId}`);
      window.location.href = `/#${sectionId}`;
    };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-color-primary ">
      <div className="container">
        <Link className="navbar-brand text-white" to="/">Todeit</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
          <li className="nav-item">
              <Link className="nav-link text-white" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <button className="nav-link text-white btn btn-link" onClick={() => handleNavigation('features')}>Caracteristicas</button>
            </li>
            <li className="nav-item">
              <button className="nav-link text-white btn btn-link" onClick={() => handleNavigation('download')}>Descargar</button>
            </li>
            <li className="nav-item">
              <button className="nav-link text-white btn btn-link" onClick={() => handleNavigation('contact')}>Contacto</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
