import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import { getDoc, updateDoc, doc } from "firebase/firestore";
import { db, auth } from "../firebaseConfig/firebase";

const Edit = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [instruccions, setInstruccions] = useState("");
  const [details, setDetails] = useState("");
  const [image, setImage] = useState("");
  const [logo, setLogo] = useState("");

  const navigate = useNavigate();

  const [user] = useAuthState(auth);
  if (!user) navigate("/");

  const { id } = useParams();

  const update = async (e) => {
    e.preventDefault();
    const challenges = doc(db, "challenges", id);
    const data = {
      title: title,
      description: description,
      instruccions: instruccions,
      image: image,
      logo: logo,
    };
    await updateDoc(challenges, data);
    navigate("/show");
  };
  const getChallenge = async (id) => {
    await getDoc(doc(db, "challenges", id))
      .then((doc) => {
        if (doc.exists()) {
          setTitle(doc.data().title);
          setDescription(doc.data().description);
          setInstruccions(doc.data().instruccions);
          setDetails(doc.data().details);
          setImage(doc.data().image);
          setLogo(doc.data().logo);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  useEffect(() => {
    getChallenge(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <form onSubmit={update}>
            <div className="mb-3">
              <label className="form-label">Title</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                id="description"
                rows="3"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="mb-3">
              <label className="form-label">instruccions</label>
              <textarea
                className="form-control"
                id="instruccions"
                rows="3"
                value={instruccions}
                onChange={(e) => setInstruccions(e.target.value)}
              ></textarea>
            </div>
            <div className="mb-3">
              <div class="row">
                <div class="col-sm">
                  <label className="form-label">estimate</label>
                  <textarea
                    className="form-control"
                    id="instruccions"
                    rows="3"
                    value={details.estimate}
                  ></textarea>
                </div>
                <div class="col-sm">
                  <label className="form-label">price</label>
                  <textarea
                    className="form-control"
                    id="instruccions"
                    rows="3"
                    s
                    value={details.price}
                  ></textarea>
                </div>
                <div class="col-sm">
                  <label className="form-label">time</label>
                  <textarea
                    className="form-control"
                    id="instruccions"
                    rows="3"
                    s
                    value={details.time}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Image</label>
              <input
                type="text"
                className="form-control"
                id="image"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Logo</label>
              <input
                type="text"
                className="form-control"
                id="logo"
                value={logo}
                onChange={(e) => setLogo(e.target.value)}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Edit;
