import React, {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, getDocs, deleteDoc, doc, orderBy, query } from 'firebase/firestore'
import { db, auth, logout } from '../firebaseConfig/firebase'


import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

const Show = () => {
  
    const navigate = useNavigate();
    const [challenges, setChallenges] = useState([])


    const [user, loading] = useAuthState(auth);
    if (!user) navigate("/");
    const challengesCollection = query(collection(db, "challenges"),orderBy("id", "asc"))
    
    const getChallenges = async () => {
        const data = await getDocs(challengesCollection)
        //console.log(data.docs)
        setChallenges(
            data.docs.map((doc) => ({...doc.data(),id:doc.id}))
        )
    }

    const deletePlace = async (id) => {
      const challengesDoc = doc(db, "challenges", id)
      await deleteDoc(challengesDoc)
      getChallenges()
    }

    const confirmDelete = (id) => {
      MySwal.fire({
        title: <p>Are you sure?</p>,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Delete`,
        denyButtonText: `Don't delete`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          deletePlace(id)
          MySwal.fire('Deleted!', '', 'success')
        } else if (result.isDenied) {
          MySwal.fire('Changes are not saved', '', 'info')
        }
      })
    }

  const handleLogout =  () => {
    logout();
    navigate("/");
  }

    useEffect (() => {
        if (loading) return;
        //if (!user) return navigate("/");
        getChallenges()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])



  return (
    <>
    <div className="container">
      <div className="row">
        <div className="table-responsive">
          <div className='d-grid gap-2 mb-2'>
            <Link to="/create" className='btn btn-primary'>Create</Link>
          </div>
          <button className="dashboard__btn" onClick={handleLogout}>
          Logout
          </button>
          <table className="border table table-zebra w-full">
            <thead>
              <tr>
                <th className='bg-blue-700 text-black p-2 w-auto'>Title</th>
                <th className='bg-blue-700 text-black p-2 w-auto'>Description</th>
                <th className='bg-blue-700 text-black p-2 w-auto'>Instruccions</th>
                <th className='bg-blue-700 text-black p-2 w-auto'>Favoritos</th>
                <th className='bg-blue-700 text-black p-2 w-auto'>unlock</th>
                <th className='bg-blue-700 text-black p-2 w-auto'>Actions</th>
              </tr>
            </thead>
            <tbody>
              { challenges.map((challenges) => ( 
                <tr className='bg-blue-100 text-blue-900' key={challenges.id}>
                  <td className='border border-slate-600'>{challenges.title}</td>
                  <td className='border border-slate-700'>{challenges.description}</td>
                  <td className='border border-slate-700'>{challenges.instruccions}</td>
                  <td className='border border-slate-700'>{challenges.favorites.length}</td>
                  <td className='border border-slate-700'>{challenges.unlock.length}</td>
                  <td className='border border-slate-700'>
                    <Link to={`/edit/${challenges.id}`} className='btn btn-warning'><i className='fa-solid fa-pencil'></i></Link>
                    <button onClick={() => confirmDelete(challenges.id)} className='btn btn-danger'>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </>
  )
}

export default Show