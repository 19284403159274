// src/components/DeleteAccount.js
import React, { useState } from 'react';
import { db } from '../firebaseConfig/firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Navbar from '../components/Navbar';

const DeleteAccount = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [inputCode, setInputCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [emailMessage, setEmailMessage] = useState(''); // Estado para el mensaje del envío de correo

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputCode !== confirmationCode.toString()) {
      setMessage('Código de confirmación incorrecto.');
      return;
    }
    try {
      await addDoc(collection(db, 'deleteRequests'), {
        email,
        timestamp: serverTimestamp()
      });
      setMessage('Tu solicitud ha sido enviada.');
      setEmail('');
      setInputCode('');
      setShowModal(false);
    } catch (error) {
      setMessage('Error al enviar la solicitud. Por favor, intenta nuevamente.');
      setShowModal(false);
    }
  };

  const handleSendCode = async () => {
    const functions = getFunctions();
    const sendConfirmationEmail = httpsCallable(functions, 'sendConfirmationEmail');
    try {
      const result = await sendConfirmationEmail({ email });
      setConfirmationCode(result.data.code);
      setCodeSent(true);
      setEmailMessage('Se ha enviado un código de confirmación a tu correo electrónico.');
    } catch (error) {
      setEmailMessage('Error al enviar el correo de confirmación. Por favor, intenta nuevamente.');
      setShowModal(false);
    }
  };

  const handleShowModal = () => {
    if (!codeSent) {
      handleSendCode();
    }
    setShowModal(true); // Mostrar el modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Cerrar el modal
  };

  return (
    
    <div className="landing-page">
      <Navbar />
      <div className='container mt-5'>
      <h1 className="text-center">Solicitar Eliminación de Cuenta</h1>
      <form onSubmit={(e) => e.preventDefault()} className="mt-4">
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Correo Electrónico</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="button" className="btn btn-danger mt-3" onClick={handleShowModal}>Enviar Solicitud</button>
      </form>
      {message && (
        <div className={`alert ${message.includes('Error') ? 'alert-danger' : 'alert-success'} mt-4`}>
          {message}
        </div>
      )}
      {showModal && (
        <div className={`modal fade show`} tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirmar Eliminación de Cuenta</h5>
                <button type="button" className="close" aria-label="Close" onClick={handleCloseModal}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {emailMessage && (
                  <div className={`alert ${emailMessage.includes('Error') ? 'alert-danger' : 'alert-success'}`}>
                    {emailMessage}
                  </div>
                )}
                <div className="alert alert-warning" role="alert">
                  <h4 className="alert-heading">Advertencia</h4>
                  <p>Esta opción no es reversible. La eliminación de la cuenta puede tardar entre 15 y 30 días en completarse.</p>
                  <hr />
                  <p className="mb-0">Por favor, asegúrate de que realmente deseas eliminar tu cuenta antes de proceder.</p>
                </div>
                {codeSent && (
                  <div className="mb-3">
                    <label htmlFor="confirmationCode" className="form-label">Código de Confirmación</label>
                    <input
                      type="text"
                      className="form-control"
                      id="confirmationCode"
                      value={inputCode}
                      onChange={(e) => setInputCode(e.target.value)}
                      required
                    />
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cancelar</button>
                {codeSent ? (
                  <button type="button" className="btn btn-danger" onClick={handleSubmit}>Confirmar Eliminación</button>
                ) : (
                  <button type="button" className="btn btn-primary" onClick={handleSendCode}>Enviar Código</button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default DeleteAccount;
