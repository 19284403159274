import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, addDoc } from "firebase/firestore";
import { db, auth } from "../firebaseConfig/firebase";

const Create = () => {
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [estimate, setEstimate] = useState("");
  const [time, setTime] = useState("");
  const [instruccions, setInstruccions] = useState("");

  const navigate = useNavigate();

  const [user] = useAuthState(auth);
  if (!user) navigate("/");

  const challengesCollection = collection(db, "challenges");

  const store = async (e) => {
    e.preventDefault();

    try {
      // add place to firestore
      await addDoc(challengesCollection, {
        id: id,
        title: title,
        description: description,
        details: {
          price: price,
          estimate: estimate,
          time: time,
        },
        image: "dateAll.jpg",
        logo: "dateAll.jpg",
        instruccions: instruccions,
        showItem: [],
        favorites: [],
        unlock: [],
        complete: {},
      });
      // navigate to home page
      navigate("/show");
    } catch (error) {
      console.error(error);
    }
    navigate("/show");
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <form onSubmit={store}>
            <div className="form-group">
              <label htmlFor="id">Id</label>
              <input
                type="text"
                className="form-control"
                id="id"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Title</label>
              <input
                type="text"
                className="form-control"
                id="title"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                id="description"
                rows="3"
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="mb-3">
              <label className="form-label">Price</label>
              <input
                type="text"
                className="form-control"
                id="price"
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Estimate</label>
              <input
                type="text"
                className="form-control"
                id="estimate"
                onChange={(e) => setEstimate(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Time</label>
              <input
                type="text"
                className="form-control"
                id="time"
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Instruccions</label>
              <textarea
                className="form-control"
                id="instruccions"
                rows="3"
                onChange={(e) => setInstruccions(e.target.value)}
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Create;
